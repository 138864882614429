import request from '@/services/request'
import apis from '@/services/apis'

/*
  Get Blockchain table List
*/
export const getBlockchain = (parmas) => {
  return request.get({
    url: `${parmas.moduleApi}/${parmas.id}/blockchain_transactions`
  })
}
/*
  Get  Physical Supplier List
*/
export const getPhysicalSupplierList = () => {
  return request.get({
    url: `${apis.sharedCompanyBaseUrl}?pageNumber=1&pageSize=9999&physicalSupplier=true`,
  })
}

export const getAllCreditAssociationsCompanyList = (currentCompanyId) => {
  return request.get({
    url: `${apis.creditAssociations}?pageSize=9999&filter=sysOrganizationId:'${currentCompanyId}'`
  })
}
export const getInternalRoleCodes = () => {
  return request.get({
    url: apis.internalRoleCodes
  })
}

export const getAuthorizedBdnCompanies = (companyId) => {
  return request.get({
    url: `${apis.bdnLicenseAuthorization}/organizations/${companyId}/authorized_bdn_organizations`,
  });
}

export const getFileBlobUrl = async (fileId) => {
  const res = await request.getFile({url: `app/sysFile/${fileId}/preview`})
  const blob = new Blob([res], { type: res.type })
  const url = URL.createObjectURL(blob)

  // revokeObjectURL after 20min
  setTimeout(() => {
    URL.revokeObjectURL(url)
  }, 1200000)

  return url
}

export const getReceivingVesselFromMpaByImoNo = (imoNo) => {
  return request.get({
    url: `${apis.mpaReceivingVessels}?imoNo=${imoNo}`
  })
}
export const getReceivingVesselListBySearch = (searchKey, companyId, pageNumber) => {
 let pageForSearch;
  if( pageNumber!=null && pageNumber>=1 ){ pageForSearch = `pageNumber=${pageNumber}&pageSize=10&`}
  let url = `${apis.searchReceivingVessels}?${pageForSearch?pageForSearch:''}name=${searchKey}&licenseNo=${searchKey}&imoNo=${searchKey}`
  if (companyId != null) {
    url += `&companyId=${companyId}`
  }
  return request.get({
    url
  })
}

export const getReceivingVesselsBySearch = ({name, imoNo, licenseNo, companyId}) => {
  let params = []
  name = name?.trim()?.toUpperCase()
  if(name) {
    params.push(`name=${name}`) 
  }

  imoNo = imoNo?.trim()?.toUpperCase()
  if(imoNo) {
    params.push(`imoNo=${imoNo}`)
  }

  licenseNo = licenseNo?.trim()?.toUpperCase()
  if(licenseNo) {
    params.push(`licenseNo=${licenseNo}`)
  }

  companyId = companyId?.trim()?.toUpperCase()
  if(companyId) {
    params.push(`companyId=${companyId}`)
  }

  const searchKey = params.join('&')
  let url = `${apis.searchReceivingVessels}?${searchKey}`
  return request.get({ url })
}

export const getEbdnMfmItemByBargeId = (bargeId) => {
  return request.get({
    url: `${apis.getEbdnMfmItemBarge}?shipId=${bargeId}`
  })
}
export const postEbdnMfmItemBarge = (data) => {
  const url =apis.postEbdnMfmItemBarge;
  return request.post({
    url,
    data
  })
}

export const getShipByShipId = (shipId) => {
  return request.get({
    url: `${apis.shipBaseUrl}/${shipId}`
  }).then(res=>{
    if (res.code ==1000 && res.data !=null) {
      return res.data
    }else{
      return {}
    }
  })
}



